/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-2/4 md:w-6/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4 p-2 rounded" style="background-color: #181d2a">Data Source & Media</h3>
            <div class="flex items-between justify-between align-middle pb-3 pt-3 dataClassificationBlock">
              <h4 class="self-center font-light">
                Bound paper copies of internal audit & accounting report from a private financial organization.
              </h4>
              <vue-draggable-container id="wb1" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">1</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle pb-3 pt-3 dataClassificationBlock">
              <h4 class="self-center font-light">
                USB Flash drive containing reports of financial audit data for a publicly traded organization.
              </h4>
              <vue-draggable-container id="wb2" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">2</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle pb-3 pt-3 dataClassificationBlock">
              <h4 class="self-center font-light">
                SQL database records of driver’s license and Date of Birth on a reusable magnetic backup tape.
              </h4>
              <vue-draggable-container id="wb3" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">3</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle pb-3 pt-3 dataClassificationBlock">
              <h4 class="self-center font-light">Deactivated spinning mechanical HDD holding application software code.</h4>
              <vue-draggable-container id="wb4" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">4</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle pb-3 pt-3 dataClassificationBlock">
              <h4 class="self-center font-light">NVME M.2 SSD holding employee records including SSN and DOB.</h4>
              <vue-draggable-container id="wb5" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">5</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle pb-3 pt-3 dataClassificationBlock">
              <h4 class="self-center font-light">Paper copies of healthcare records containing healthcare data.</h4>
              <vue-draggable-container id="wb6" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">6</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle pb-3 pt-3 dataClassificationBlock">
              <h4 class="self-center font-light">Old fashioned spinning hard disk containing pictures from company holiday party.</h4>
              <vue-draggable-container id="wb7" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">7</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle pb-3 pt-3 dataClassificationBlock">
              <h4 class="self-center font-light">
                Paper copies containing reports from company vulnerability analysis and risk assessments.
              </h4>
              <vue-draggable-container id="wb8" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">8</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle pb-3 pt-3 dataClassificationBlock">
              <h4 class="self-center font-light">Flash Drive containing company logo files and marketing tagline.</h4>
              <vue-draggable-container id="wb9" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">9</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle pb-1 pt-3 dataClassificationBlock">
              <h4 class="self-center font-light">Papers containing company differentiated product design blueprint.</h4>
              <vue-draggable-container id="wb10" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">10</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle my-2 pt-4 dataClassificationBlock">
              <h4 class="self-center font-light">
                USB Flash drive containing KFC's secret recipe “blend of 11 herbs and spices” used on its Kentucky Fried Chicken.
              </h4>
              <vue-draggable-container id="wb11" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">11</h5>
              </vue-draggable-container>
            </div>
            <div class="flex items-between justify-between align-middle mt-2 dataClassificationBlock">
              <h4 class="self-center font-light">DVD containing company pricing data, responses to RFPs from last 5 years.</h4>
              <vue-draggable-container id="wb12" class="wbDataClassification">
                <h5 class="dragBlock dataClassificationDragBlock cursor-pointer">12</h5>
              </vue-draggable-container>
            </div>
          </vx-card>
        </div>
        <div class="vx-col lg:w-2/4 md:w-6/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="pb-0" @click="click_remove">
              <div class="vx-row">
                <div class="vx-col w-6/12 rounded">
                  <h2 class="font-bold p-2 rounded text-center text-2xl" style="background-color: #181d2a">Data Classification Type</h2>
                  <div class="rounded-lg p-4 my-4 bg-light main-border">
                    <h4 class="font-semibold mb-2">PII</h4>
                    <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerDataClassification" id="inputPII" />
                  </div>
                  <div class="rounded-lg p-4 my-4 bg-light main-border">
                    <h4 class="font-semibold mb-2">PHI</h4>
                    <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerDataClassification" id="inputPHI" />
                  </div>
                  <div class="rounded-lg p-4 my-4 bg-light main-border">
                    <h4 class="font-semibold mb-2">Intellectual Property</h4>
                    <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerDataClassification" id="inputIP" />
                  </div>
                  <div class="rounded-lg p-4 my-4 bg-light main-border">
                    <h4 class="font-semibold mb-2">Corporate Confidential</h4>
                    <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerDataClassification" id="inputCC" />
                  </div>
                  <div class="rounded-lg p-4 my-4 bg-light main-border">
                    <h4 class="font-semibold mb-2">Public</h4>
                    <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerDataClassification" id="inputPUB" />
                  </div>
                  <div class="rounded-lg p-4 my-4 bg-light main-border">
                    <h4 class="font-semibold mb-2">Trademark</h4>
                    <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerDataClassification" id="inputTM" />
                  </div>
                  <div class="rounded-lg p-4 my-4 bg-light main-border">
                    <h4 class="font-semibold mb-2">Patent</h4>
                    <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerDataClassification" id="inputPAT" />
                  </div>
                  <div class="rounded-lg p-4 mt-4 bg-light main-border">
                    <h4 class="font-semibold mb-2">Trade Secret</h4>
                    <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerDataClassification" id="inputTS" />
                  </div>
                </div>

                <div class="vx-col w-6/12 rounded">
                  <h2 class="font-bold text-2xl p-2 rounded text-center" style="background-color: #181d2a">Data Sanitization Type</h2>
                  <div class="rounded-lg p-4 my-4 bg-light main-border">
                    <h4 class="font-semibold mb-2">Degaussing and Multi-Pass Wipe</h4>
                    <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerDataClassification" id="inputDAMPW" />
                  </div>
                  <div class="rounded-lg p-4 my-4 bg-light main-border">
                    <h4 class="font-semibold mb-2">Physical Destruction using Shredding</h4>
                    <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerDataClassification" id="inputPDVS" />
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,
      //last_drag_error: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      const uuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      this.draggingCard = true;
      x.data.originalSource.id = uuid;
      const originalNode = x.data.originalSource;
      const clonedNode = originalNode.cloneNode(true);
      clonedNode.classList.add('clonedElement');
      clonedNode.id += '-cloned';
      originalNode.parentNode.insertBefore(clonedNode, originalNode.nextSibling);
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const origSourceContainer = x.data.dragEvent.data.originalSource;
      if (overContainer.classList.contains('wbDataClassification')) {
        if (overContainer.children.length > 0) {
          return x.cancel();
        }
      }
      if (overContainer && overContainer.classList.contains('dropzone')) {
        const id = origSourceContainer.id;
        document.getElementById(`${id}-cloned`).style.display = 'inline-flex';
        document.getElementById(id).classList.remove('dragBlock');
        document.getElementById(id).classList.add('stillBlockDataClassification');
        return;
      }
      document.getElementById(`${origSourceContainer.id}-cloned`).outerHTML = '';
    },
    markSimulation() {
      let totalScore = 0;

      function checkAnswers(correctAnswers, inputKey) {
        const inputElem = document.getElementById(inputKey);
        const userAnswers = [];

        inputElem.childNodes.forEach((item) => {
          const text = parseInt(item.textContent);
          if (correctAnswers.includes(text)) {
            userAnswers.push(text);
          }
        });

        const sortedCorrectAnswers = [...correctAnswers].sort();
        const sortedUserAnswers = [...userAnswers].sort();

        const score = sortedCorrectAnswers.reduce((count, answer, index) => {
          if (answer === sortedUserAnswers[index]) {
            count++;
          }
          return count;
        }, 0);

        if (score === correctAnswers.length && inputElem.childNodes.length === correctAnswers.length) {
          inputElem.style.borderColor = '#28C76F';
          inputElem.style.backgroundColor = '#28C76F';
          totalScore += score;
        } else {
          inputElem.style.borderColor = '#fd4445';
          inputElem.style.backgroundColor = '#fd4445';
        }
      }

      checkAnswers([3, 5], 'inputPII');
      checkAnswers([6], 'inputPHI');
      checkAnswers([4], 'inputIP');
      checkAnswers([1, 8, 12], 'inputCC');
      checkAnswers([2, 7], 'inputPUB');
      checkAnswers([9], 'inputTM');
      checkAnswers([10], 'inputPAT');
      checkAnswers([11], 'inputTS');
      checkAnswers([7, 4, 3], 'inputDAMPW');
      checkAnswers([1, 2, 5, 6, 8, 9, 10, 11, 12], 'inputPDVS');

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    click_remove(x) {
      const target = x.target;
      if (target.classList.contains('stillBlockDataClassification')) {
        target.outerHTML = '';
      }
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.dataClassificationDragBlock {
  min-width: 50px !important;
  min-height: 50px !important;
  background-color: rgb(0, 85, 255, 0.3);
  color: #fff !important;
  font-weight: 800 !important;
}

.dataClassificationBlock {
  border-color: #181d2a;
  border-style: solid;
  border-width: 1px 0 0 0;
  padding: 7px 0 7px 0;
}

.answerDataClassification {
  min-height: 52px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.4rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.wbDataClassification {
  justify-content: center;
  display: flex;
  padding: 0.6rem;
}

.stillBlockDataClassification {
  padding: 12px;
  word-break: break-all;
  background-color: rgba(var(--vs-primary), 1);
  display: inline-flex;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 200 !important;
  font-weight: 800 !important;
  cursor: url('~@/assets/images/cursor/trash-cursor.png'), auto !important;
  background-color: #011d56;
  border: 1px solid #0053ff;
  color: #fff !important;
  width: 100%;
}
</style>
